<template>
	<div style="height: 85vh; overflow: hidden; overflow-y: scroll">
		<el-form :model="form" :rules="rules" ref="form" label-position="left" label-width="100px" class="demo-form">
			<div class="flex bewteen wrap">
				<div class="width_40">
					<el-form-item label="企业名称" prop="company_name" required label-width="150px">
						<el-input v-model="form.company_name"></el-input>
					</el-form-item>
				</div>
				<div class="width_40">
					<el-form-item label="统一社会信用代码" prop="credit_code" required label-width="150px">
						<el-input v-model="form.credit_code"></el-input>
					</el-form-item>
				</div>
				<div class="width_40">
					<el-form-item label="法人代表" prop="legal_name" required label-width="150px">
						<el-input v-model="form.legal_name"></el-input>
					</el-form-item>
				</div>
				<div class="width_40">
					<el-form-item label="法人联系电话" prop="legal_mobile" required label-width="150px">
						<el-input v-model="form.legal_mobile"></el-input>
					</el-form-item>
				</div>
				<div class="width_40">
					<el-form-item label="企业联系人" prop="company_user" required label-width="150px">
						<el-input v-model="form.company_user"></el-input>
					</el-form-item>
				</div>
				<div class="width_40">
					<el-form-item label="联系电话" prop="company_mobile" required label-width="150px">
						<el-input v-model="form.company_mobile"></el-input>
					</el-form-item>
				</div>
				<div class="width_40">
					<el-form-item label="电子邮箱" prop="email" required label-width="150px">
						<el-input v-model="form.email"></el-input>
					</el-form-item>
				</div>
				<div class="width_40">
					<el-form-item label="从业人员" prop="people_num" required label-width="150px">
						<el-input v-model="form.people_num"></el-input>
					</el-form-item>
				</div>
				<div class="width_40">
					<el-form-item label="所处阶段" prop="company_status" required label-width="150px">
						<el-select v-model="form.company_status" placeholder="请选择">
							<el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				<div class="width_40">
					<el-form-item label="企业性质" prop="company_type" required label-width="150px">
						<el-select v-model="form.company_type">
							<el-option v-for='item in companyTList' :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
				</div>
				<div class="width_40">
					<el-form-item label="注册资本/开办资金（万元）" prop="capital" required label-width="150px">
						<el-input v-model="form.capital"></el-input>
					</el-form-item>
				</div>
				<div class="width_40">
					<el-form-item label="注册时间" prop="register_time" required label-width="150px">
						<el-date-picker clearable type="date" placeholder="选择日期" v-model="form.register_time"
							style="width: 100%"></el-date-picker>
					</el-form-item>
				</div>
				<div class="width_40">
					<el-form-item label="注册地址" prop="register_region" required label-width="150px">
						<el-select v-model="form.register_region" placeholder="请选择" clearable @clear='clearClass(1)'>
							<el-option v-for="item in options" :value="item.name"
								@click.native='setAddressId(item.id, 1)'>
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				<div class="width_40">
					<el-form-item label="企业所在地" prop="com_region" required label-width="150px">
						<el-select v-model="form.com_region" placeholder="请选择" clearable @clear='clearClass(2)'>
							<el-option v-for="item in options" :value="item.name"
								@click.native='setAddressId(item.id, 2)'>
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				<div class="width_40">
					<el-form-item label="注册详细地址" prop="registers_address" required label-width="150px">
						<el-input :autosize="{ minRows: 3, maxRows: 99}" type="textarea"
							v-model="form.registers_address"></el-input>
					</el-form-item>
				</div>
				<div class="width_40">
					<el-form-item label="所在地详细地址" prop="coms_address" required label-width="150px">
						<el-input type="textarea" v-model="form.coms_address" :autosize="{ minRows: 3, maxRows: 99}">
						</el-input>
					</el-form-item>
				</div>
				<div class="width_40">
					<el-form-item label="企业网址" prop="name" label-width="150px">
						<el-input v-model="form.company_url" placeholder="请输入"></el-input>
					</el-form-item>
				</div>
				<div class="width_40">
					<el-form-item label="主营产品" prop="name" label-width="150px">
						<el-input v-model="form.good" placeholder="请输入"></el-input>
					</el-form-item>
				</div>
				<div style="width: 100%">
					<el-form-item label="服务行业" required label-width="150px">
						<el-select v-model="form.server_trade1" placeholder="请选择" style="margin-right: 40px" clearable
							@clear='clearId(1)'>
							<el-option v-for="item in serverList1" :value="item.name"
								@click.native='setTwo1(item.server_id)'>
							</el-option>
						</el-select>
						<el-select v-model="form.server_trade2" placeholder="请选择" style="margin-right: 40px" clearable
							@clear='clearId(2)'>
							<el-option v-for="item in serverList2" :value="item.name"
								@click.native='setThree1(item.server_id)'>
							</el-option>
						</el-select>
						<el-select v-model="form.server_trade3" placeholder="请选择" style="margin-right: 40px" clearable
							@clear='clearId(3)'>
							<el-option v-for="item in serverList3" :value="item.name"
								@click.native='setFour1(item.server_id)'>
							</el-option>
						</el-select>
						<el-select v-model="form.server_trade4" placeholder="请选择" style="margin-right: 40px" clearable
							@clear='clearId(4)'>
							<el-option v-for="item in serverList4" :value="item.name"
								@click.native='setFourId(item.server_id)'>
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				<div style="width: 100%">
					<el-form-item label="是否有抵押物" label-width="150px">
						<div class="flex">
							<div class="mar_40">
								<el-radio-group v-model="form.mortgage_status" @change="mortgageStatusChange">
									<el-radio :label="0">否</el-radio>
									<el-radio :label="1">是</el-radio>
								</el-radio-group>
							</div>
							<div>
								<!-- <el-input
                  v-model="form.mortgage_text"
                  :disabled="!form.mortgage_status"
                  placeholder='请输入抵押物'
                ></el-input> -->
								<el-select v-model="form.mortgage_text" placeholder="请选择抵押物" clearable>
									<el-option v-for="item in pawnList" :value="item">
									</el-option>
								</el-select>
							</div>
						</div>
					</el-form-item>
				</div>

				<div style="width: 100%; padding-left: 55px;">
					<div style="width: 140px" class="required">近三年主要指标</div>
					<div class="flex column bewteen width" style="line-height: 50px; padding: 0 30px">
						<div class="flex bewteen width">
							<div class="width_20">年份</div>
							<div class="width_20">总资产（万元）</div>
							<div class="width_20">销售收入（万元）</div>
							<div class="width_20">净利润（万元）</div>
							<div class="width_20">经营性现金流量（万元）</div>
							<div class="width_20">经营性净流量（万元）</div>
							<div class="width_20">流量比利（%）</div>
						</div>
						<div class="flex bewteen" v-for="(item, index) in threeYearList" :key="index">
							<div class="encertification_indicators_select width_20 mar_40">
								<el-select v-model="item.year" placeholder="请选择年份" clearable>
									<el-option v-for="item in options1" :value="item.name">
									</el-option>
								</el-select>
								<span>年</span>
							</div>

							<div class="mar_40" style="width: 18.6%;">
								<el-input v-model="item.total_inv" placeholder="请输入内容"></el-input>
							</div>
							<div class="mar_40" style="width: 18.6%;">
								<el-input v-model="item.sale_rev" placeholder="请输入内容"></el-input>
							</div>
							<div class="mar_40" style="width: 18.6%;">
								<el-input v-model="item.net_profit" placeholder="请输入内容"></el-input>
							</div>
							<div class="mar_40" style="width: 18.6%;">
								<el-input v-model="item.cash_flow" placeholder="请输入内容"></el-input>
							</div>
							<div class="mar_40" style="width: 18.6%;">
								<el-input v-model="item.net_flow" placeholder="请输入内容"></el-input>
							</div>
							<div class="mar_40" style="width: 18.6%;">
								<el-input v-model="item.flow_radio" placeholder="请输入内容"></el-input>
							</div>
						</div>
					</div>
				</div>

				<div style="display: flex; align-items: center; justify-content: space-between;width: 100%;">
					<div style="width: 100%; padding-left: 55px;" class="mab_20">
						<div class="mab_20">
							<span style="color: red">*</span>
							<span>上传营业执照/法人证书</span>
						</div>
						<el-upload ref='enterList' list-type="picture-card" :file-list="enterList"
							class='avatar-uploader' action="#" accept=".jpg,.jpeg,.png" :multiple="true" :limit="1"
							name="file" :http-request="httpreq1" :on-exceed='handleExceed' :on-remove="handleRemove"
							:before-upload="handleBeforeUpload">
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>

					<!-- <div style="width: 100%; padding-left: 55px;" class="mab_20">
               <div class="mab_20">
                    <span style="color: red">*</span>
                    <span>上传信息真实性承诺书</span>
               </div>
               <el-upload
                    ref='enterList'
                    list-type="picture-card"
                    :file-list="realList"
                    class='avatar-uploader'
                    action="#"
                    accept=".jpg,.jpeg,.png"
                    :multiple="true"
                    :limit="1"
                    name="file"
                    :http-request="httpreq2"
                    :on-exceed='handleExceed'
                    :on-remove="handleRemove2"
                    :before-upload="handleBeforeUpload">
                    <i class="el-icon-plus"></i>
              </el-upload>
          </div> -->
				</div>


				<div style='margin-left: 50px;'>
					<el-button type="primary" @click="submitForm('form')" v-if="is_adopt == -1">立即审核</el-button>
					<el-button type="warning" v-if="is_adopt == 0" disabled>审核中</el-button>
					<el-button type="primary" v-if="is_adopt == 1" disabled>审核通过</el-button>
					<el-button type="danger" @click="submitForm('form')" v-if="is_adopt == 2">审核不通过，重新提交</el-button>

					<span v-if="is_adopt == 2"
						style='color: red; font-size: 15px; margin-left: 12px;'>(不通过原因：{{no_adopt}})</span>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
	import {
		Loading
	} from 'element-ui'

	export default {
		data() {
			return {
				fileName: '',
				loadElemnt: '',
				dialogVisible: false,
				options: [],
				options1: [],
				years: '',
				enterList: [],
				realList: [],
				pawnList: ['建筑物和其他土地附着物', '建设用地使用权', '海域使用权', '生产设备、原材料、半成品、产品', '正在建造的建筑物、船舶、航空器', '交通运输工具',
					'法律、行政未禁止抵押的其他财产'
				],
				companyList: [{
					name: '种子期',
					id: 1
				}, {
					name: '成长期',
					id: 2
				}, {
					name: '扩张期',
					id: 3
				}, {
					name: '上市筹备期',
					id: 4
				}, {
					name: '第二轮融资期',
					id: 5
				}],
				form: {
					company_name: "", // 企业名称
					credit_code: "", // 社会信用代码
					legal_name: "", // 法人代表
					legal_mobile: "", //法人号码
					company_user: "", // 企业联系人
					company_mobile: "", // 企业联系人电话
					email: "", // 企业邮箱
					company_type: "", // 企业性质
					company_status: "", // 企业所属阶段
					people_num: '', //从业人数
					capital: "", //注册资本(万元)
					register_time: "", //企业注册时间
					register_region: '', //企业注册地址id
					com_region: '', //企业通讯地址id
					registers_address: "", //企业注册详细地址
					coms_address: "", //通讯详情地址
					company_url: "", //企业网址
					good: "", //主营产品
					server_trade1: '', //服务行业
					server_trade2: '',
					server_trade3: '',
					server_trade4: '',
					mortgage_status: "", //是否有抵押
					mortgage_text: "", //抵押物品介绍
					realImg: '',
					img: "" //营业执照
				},
				loadingInstances: '',
				no_adopt: '',
				rules: {
					company_name: [{
							required: true,
							message: "请输入企业名称",
							trigger: "blur"
						},
						{
							required: true,
							message: "请输入企业名称",
							trigger: "change"
						}
					],
					credit_code: [{
							required: true,
							message: "请输入社会信用代码",
							trigger: "blur"
						},
						{
							required: true,
							message: "请输入社会信用代码",
							trigger: "change"
						}
					],
					legal_name: [{
							required: true,
							message: "请输入法人代表",
							trigger: "blur"
						},
						{
							required: true,
							message: "请输入法人代表",
							trigger: "change"
						}
					],
					legal_mobile: [{
							required: true,
							message: "请输入法人号码",
							trigger: "blur"
						},
						{
							required: true,
							message: "请输入法人号码",
							trigger: "change"
						}
					],
					company_user: [{
							required: true,
							message: "请输入企业联系人",
							trigger: "blur"
						},
						{
							required: true,
							message: "请输入企业联系人",
							trigger: "change"
						}
					],
					company_mobile: [{
							required: true,
							message: "请输入企业联系人电话",
							trigger: "blur"
						},
						{
							required: true,
							message: "请输入企业联系人电话",
							trigger: "change"
						}
					],
					email: [{
							required: true,
							message: "请输入企业邮箱",
							trigger: "blur"
						},
						{
							required: true,
							message: "请输入企业邮箱",
							trigger: "change"
						}
					],
					company_type: [{
							required: true,
							message: "请输入企业性质",
							trigger: "blur"
						},
						{
							required: true,
							message: "请输入企业性质",
							trigger: "change"
						}
					],
					company_status: [{
						required: true,
						message: "请选择企业所属阶段",
						trigger: "change"
					}, ],
					people_num: [{
						required: true,
						message: "请输入从业人员",
						trigger: "change"
					}],
					capital: [{
						required: true,
						message: "请输入注册资本/开办资金（万元）",
						trigger: "change",
					}, ],
					register_time: [{
						required: true,
						message: "请选择注册时间",
						trigger: "change"
					}, ],
					register_region: [{
						required: true,
						message: "请选择注册地址",
						trigger: "change"
					}, ],
					com_region: [{
						required: true,
						message: "请选择企业所在地",
						trigger: "change"
					}, ],
					registers_address: [{
							required: true,
							message: "请输入注册详细地址",
							trigger: "blur"
						},
						{
							required: true,
							message: "请输入注册详细地址",
							trigger: "change"
						}
					],
					coms_address: [{
							required: true,
							message: "请输入所在地详细地址",
							trigger: "blur"
						},
						{
							required: true,
							message: "请输入所在地详细地址",
							trigger: "change"
						}
					],
					server_trade: [{
						required: true,
						message: "请选择服务行业",
						trigger: "change"
					}, ]
				},
				diyaFlag: true, // 抵押输入的禁用
				serverList1: [], // 服务行业
				serverList2: [],
				serverList3: [],
				serverList4: [],
				cityList: [],
				cityListProps: {
					value: "id", //匹配响应数据中的id
					label: "name", //匹配响应数据中的name
					children: "chilren", //匹配响应数据中的children
				},
				radio: 3,
				input: "",
				threeYearList: [ // 近三年指标
					{
						year: '',
						total_inv: '',
						sale_rev: '',
						net_profit: '',
						cash_flow: '',
						net_flow: '',
						flow_radio: '',
						type: 1
					},
					{
						year: '',
						total_inv: '',
						sale_rev: '',
						net_profit: '',
						cash_flow: '',
						net_flow: '',
						flow_radio: '',
						type: 2
					},
					{
						year: '',
						total_inv: '',
						sale_rev: '',
						net_profit: '',
						cash_flow: '',
						net_flow: '',
						flow_radio: '',
						type: 3
					}
				],
				companyTList: [],
				oneId: '',
				twoId: '',
				threeId: '',
				fourId: '',
				is_adopt: -1,
				aTrade: [],
				nowTime: 0,
				lastTime: 0,
				addressId1: '',
				addressId2: '',
				flaga: 0
			};
		},
		methods: {
			getCompanyType() {
				let data = {}

				this.$post('/company_type', data).then(res => {
					if (res.data.status == 1) {
						this.companyTList = res.data.result
					}
				})
			},
			handleBeforeUpload(file) {
				let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1)
				const extension = testmsg === "jpg"
				const extension1 = testmsg === "jpeg"
				const extension2 = testmsg === "png"

				if (!extension && !extension1 && !extension2) {
					this.$message({
						message: "上传文件只能是.jpg、.jpeg、.png格式!",
						type: "warning",
						duration: 1500
					})
					return false
				}
			},
			handleExceed() {
				this.$message({
					message: '最大上传数量为1张!',
					type: 'warning',
					duration: 1500
				})
			},
			handleRemove() {
				this.enterList = []
				this.form.img = ''
			},
			handleRemove2() {
				this.realList = []
				this.form.realImg = ''
			},
			httpreq1(load) {
				let loadingInstance = Loading.service({
					fullscreen: true,
					text: '正在上传'
				})

				let formdata = new FormData()
				formdata.append('file', load.file)

				this.$post('upload_file', formdata).then(res => {
					this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
						loadingInstance.close()
					})

					if (res.data.status == 1) {
						this.form.img = res.data.address
					}
				})

				// this.$post('upload_file', formData).then(res => {
				//    this.form.img = res.data.address
				//    this.dialogVisible = false

				//    let picObj = {url: this.form.img}
				//    this.enterList.push(picObj)

				//    this.loadElemnt.close()
				// })
			},
			httpreq2(load) {
				let loadingInstance = Loading.service({
					fullscreen: true,
					text: '正在上传'
				})

				let formdata = new FormData()
				formdata.append('file', load.file)

				this.$post('upload_file', formdata).then(res => {
					this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
						loadingInstance.close()
					})

					if (res.data.status == 1) {
						this.form.realImg = res.data.address
					}
				})
			},
			submitForm(formName) {
				let title = this.is_adopt == -1 ? '是否立即审核' : this.is_adopt == 2 ? '是否重新提交?' : ''

				this.$confirm(title, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.comSave()
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					})
				})
			},
			handleAvatarSuccess(res, file) {
				console.log(res);
				console.log(file);
			},
			beforeAvatarUpload() {},
			resourceChange(e) {
				console.log(e);
				if (e == "是") {
					this.diyaFlag = false;
				} else {
					this.diyaFlag = true;
				}
			},
			tongxunAddressChange(e) {
				console.log(e);
			},
			mortgageStatusChange(e) {
				console.log(e);
			},
			zhuceAddressChange(e) {
				console.log(this.form.register_region);
			},
			infoCom() {

				let data = {};
				data.token = localStorage.eleToken;
				this.$post("/info_com", data).then((res) => {
					console.log(res);
					if (res.data.result != '') {
						this.flaga = 1
						this.loadingInstances = Loading.service({
							fullscreen: true,
							text: '正在加载'
						})

						this.form.company_name = res.data.result.info.company_name
						this.form.credit_code = res.data.result.info.credit_code
						this.form.legal_name = res.data.result.info.legal_name
						this.form.legal_mobile = res.data.result.info.legal_mobile
						this.form.company_user = res.data.result.info.company_user
						this.form.company_mobile = res.data.result.info.company_mobile
						this.form.email = res.data.result.info.email
						this.form.company_type = typeof(res.data.result.info.company_type) == 'number' ? res.data
							.result.info.company_type : res.data.result.info.company_type - 0
						this.form.company_status = res.data.result.info.company_status
						this.form.people_num = res.data.result.info.people_num
						this.form.capital = res.data.result.info.capital
						this.form.register_time = res.data.result.info.register_time
						this.form.register_pid = res.data.result.info.register_pid
						this.form.registers_address = res.data.result.info.registers_address
						this.form.coms_address = res.data.result.info.coms_address
						this.form.img = res.data.result.info.img
						this.form.com_pid = res.data.result.info.com_pid
						this.form.mortgage_status = res.data.result.info.mortgage_status
						this.form.mortgage_text = res.data.result.info.mortgage_text
						this.form.company_url = res.data.result.info.company_url
						this.form.good = res.data.result.info.good

						if (res.data.result.target.length != 0) {
							this.threeYearList = res.data.result.target
						}

						this.no_adopt = res.data.result.info.no_adopt ? res.data.result.info.no_adopt : ''
						// this.form.register_region = [
						//   res.data.result.info.register_pid,
						//   res.data.result.info.register_region,
						// ];
						// this.form.com_region = [
						//   res.data.result.info.com_pid,
						//   res.data.result.info.com_region,
						// ];

						let picObj = {
							url: res.data.result.info.img
						}
						this.enterList.push(picObj)

						this.is_adopt = res.data.result.info.is_adopt

						this.aTrade = res.data.result.info.server_trade

						this.addressId1 = res.data.result.info.register_region
						this.addressId2 = res.data.result.info.com_region

						this.getQhCityList()

						this.searchSerive()
						this.getSeiver()

						setTimeout(() => {
							this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
								this.loadingInstances.close()
							})
						}, 500)
					} else {
						this.searchSerive()

						this.getQhCityList()
					}
				});
			},
			getSeiver() {
				if (this.is_adopt != -1 && this.aTrade) {

					if (this.aTrade[1]) {
						let data = {
							pid: this.aTrade[0]
						}

						this.$post('/serverTwo', data).then(tres => {

							tres.data.result.map(item => {
								if (item.server_id == this.aTrade[1]) {
									this.twoId = item.server_id

									this.form.server_trade2 = item.name

									this.setThree(item.server_id)
								}
							})
						})
					}

					if (this.aTrade[2]) {
						let data = {
							pid: this.aTrade[1]
						}

						this.$post('/serverThree', data).then(thres => {

							thres.data.result.map(item => {
								if (item.server_id == this.aTrade[2]) {
									this.threeId = item.server_id

									this.form.server_trade3 = item.name

									this.setFour(item.server_id)

								}
							})
						})
					}

					if (this.aTrade[3]) {
						let data = {
							pid: this.aTrade[2]
						}

						this.$post('/serverFour', data).then(fres => {

							fres.data.result.map(item => {
								if (item.server_id == this.aTrade[3]) {
									this.fourId = item.server_id

									this.form.server_trade4 = item.name

								}
							})
						})
					}
				}
			},
			comSave() {
				let arr = []
				let num = 0
				if (this.oneId) {
					arr[0] = this.oneId
				}

				if (this.twoId) {
					arr[1] = this.twoId
				}

				if (this.threeId) {
					arr[2] = this.threeId
				}

				if (this.fourId) {
					arr[3] = this.fourId
				}

				this.threeYearList.map(item => {
					if (item.year && item.total_inv && item.sale_rev && item.net_profit && item.cash_flow && item.net_flow && item
						.flow_radio) {
						num++
					}
				})

				if (num != this.threeYearList.length) {
					this.$message({
						message: '近三年主要指标不能为空!',
						type: 'error'
					})
					return
				}


				let data = {
					token: localStorage.eleToken ? localStorage.eleToken : '',
					data: {
						company_name: this.form.company_name,
						credit_code: this.form.credit_code,
						legal_name: this.form.legal_name,
						legal_mobile: this.form.legal_mobile,
						company_user: this.form.company_user,
						company_mobile: this.form.company_mobile,
						email: this.form.email,
						company_type: this.form.company_type,
						company_status: this.form.company_status,
						people_num: this.form.people_num,
						capital: this.form.capital,
						register_time: this.form.register_time,
						register_region: [3126, this.addressId1], //11 注册
						register_pid: 3126, //  
						com_region: [3126, this.addressId2], //  通讯
						registers_address: this.form.registers_address,
						coms_address: this.form.coms_address,
						server_trade: arr,
						img: this.form.img,
						com_pid: 3126,
						mortgage_text: this.form.mortgage_text,
						mortgage_status: this.form.mortgage_status,
						company_url: this.form.company_url,
						good: this.form.good
					},
					target: this.threeYearList
				}


				this.$post("/com_save", data).then((res) => {
					let type = res.data.status == 1 ? 'success' : 'error'
					this.$message({
						message: res.data.msg,
						type
					})

					if (res.data.status == 1) {
						this.$router.go(0)
					}
				})
			},
			// 查询一级服务行业
			searchSerive() {
				let data = {}

				this.$post('/serverOne', data).then(res => {
					this.serverList1 = res.data.result ? res.data.result : []

					if (this.is_adopt != -1 && this.aTrade) {
						this.serverList1.map(item => {

							if (item.server_id == this.aTrade[0]) {
								this.oneId = item.server_id

								this.form.server_trade1 = item.name

								this.setTwo(item.server_id)

							}
						})
					}
				})
			},
			setTwo1(id) {
				this.serverList2 = []
				this.serverList3 = []
				this.serverList4 = []

				this.form.server_trade2 = ''
				this.form.server_trade3 = ''
				this.form.server_trade4 = ''

				this.twoId = ''
				this.threeId = ''
				this.fourId = ''

				this.setTwo(id)
			},
			setTwo(id) {
				let data = {
					pid: id
				}

				this.$post('/serverTwo', data).then(res => {
					this.serverList2 = res.data.result ? res.data.result : []

					this.oneId = id
				})
			},
			setThree1(id) {
				this.serverList3 = []
				this.serverList4 = []

				this.form.server_trade3 = ''
				this.form.server_trade4 = ''

				this.threeId = ''
				this.fourId = ''

				this.setThree(id)
			},
			setThree(id) {
				let data = {
					pid: id
				}

				this.$post('/serverThree', data).then(res => {
					this.serverList3 = res.data.result ? res.data.result : []

					this.twoId = id
				})
			},
			setFour1(id) {
				this.serverList4 = []

				this.form.server_trade4 = ''
				this.fourId = ''

				this.setFour(id)
			},
			setFour(id) {
				let data = {
					pid: id
				}

				this.$post('/serverFour', data).then(res => {
					this.serverList4 = res.data.result ? res.data.result : []

					this.threeId = id
				})
			},
			setFourId(id) {
				this.fourId = id
			},
			clearId(index) {
				if (index == 1) {
					this.oneId = ''
					this.twoId = ''
					this.threeId = ''
					this.fourId = ''

					this.form.server_trade1 = ''
					this.form.server_trade2 = ''
					this.form.server_trade3 = ''
					this.form.server_trade4 = ''
				} else if (index == 2) {
					this.twoId = ''
					this.threeId = ''
					this.fourId = ''

					this.form.server_trade2 = ''
					this.form.server_trade3 = ''
					this.form.server_trade4 = ''
				} else if (index == 3) {
					this.threeId = ''
					this.fourId = ''

					this.form.server_trade3 = ''
					this.form.server_trade4 = ''
				} else if (index == 4) {
					this.fourId = ''

					this.form.server_trade4 = ''
				}
			},
			// 适用地区
			getQhCityList() {
				let data = {}

				this.$post('/qh_city_lst', data).then(res => {
					this.options = res.data.result ? res.data.result : []

					if (this.flaga == 1) {
						res.data.result.map(item => {
							if (item.id == this.addressId1) {
								this.form.register_region = item.name
								console.log(123)
							}

							if (item.id == this.addressId2) {
								this.form.com_region = item.name
							}

							console.log(item.id)
						})

						console.log(this.addressId1, this.addressId2, res.data.result)
					}
				})
			},
			clearClass(type) {
				if (type == 1) {
					this.addressId1 = ''
				}

				if (type == 2) {
					this.addressId2 = ''
				}
			},
			setAddressId(id, type) {
				if (type == 1) {
					this.addressId1 = id
				}

				if (type == 2) {
					this.addressId2 = id
				}
			},
			getYear() {
				let date = new Date()
				let year = date.getFullYear()

				for (let i = 10; i > 0; i--) {
					let num = year - i
					this.options1.push({
						name: num
					})
				}
			}
		},
		mounted() {
			this.getYear()
			this.getCompanyType()
			this.infoCom()
		},
		destoryed() {
			this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
				this.loadingInstances.close()
			})
		}
	};
</script>

<style scoped>
	.encertification_indicators_select {
		display: flex;
		align-items: center;
	}

	.encertification_indicators_select span {
		padding: 0 8px;
		/* margin-bottom: 15px; */
	}

	/*deep/.el-form--label-left .el-form-item__label {
  text-align: left;
}*/
	/deep/.el-form--label-left .el-form-item__label {
		text-align: right;
	}

	/deep/.el-input__suffix {
		display: flex;
		align-items: center;
	}

	/*/deep/.el-select{
  height: 70px;
}*/
	./deep/.el-input--suffix {
		display: flex;
		align-items: flex-start;
	}

	.avatar-uploader .el-upload {
		border: 13px dashed #333;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 40px;
		font-weight: bold;
		color: #fd6a6a;
		background-color: #fed2d2;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
		border: 1px dashed #eee;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.mab_20 {
		margin-bottom: 20px;
	}

	.width_20 {
		width: 20%;
	}

	.cropper-content .cropper {
		width: auto;
		height: 300px;
	}

	.required::before {
		content: '*';
		color: #F56C6C;
		margin-right: 3px;
	}


	/*/deep/.el-form--label-left .el-form-item__label {
  text-align: left;
}*/
	.width_40 {
		width: 48%;
	}
</style>
